/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import hexToRgb from './hexToRgb';

// background gradient color
export default function gradient(color) {
    if (color?.rotation && color.first_color?.color && color.second_color?.color) {
        return `linear-gradient(${color.rotation},${hexToRgb(color?.first_color.color, color?.first_color_opacity)} ${color?.first_position}%,${hexToRgb(color?.second_color.color, color?.second_color_opacity)} ${color?.second_position}% )`;
    }
    return '';
}
