/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, shape,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SBSCopy from './Partial/SBSCopy';

const useStyles = makeStyles((theme) => ({
    container: (data) => ({
        position: 'relative',
        overflow: 'hidden',
        backgroundImage: `url(${data?.background_image?.url}?auto=webp)`,
        backgroundRepeat: 'no-repeat',
        maxWidth: ' 100%',
        backgroundSize: 'cover',
    }),
    image: {
        opacity: '0',
        [theme.breakpoints.down(1366)]: {
            height: '300px',
            maxWidth: '100%',
        },
        [theme.breakpoints.down(980)]: {
            height: '216px',
            maxWidth: '100%',
        },
        [theme.breakpoints.down(599)]: {
            height: 'auto',
            maxWidth: '100%',
            minHeight: 'auto',
            width: '100%',
            display: 'flex',
            opacity: '0',
        },
    },
}));

const SideBannerPanel = ({ data }) => {
    if (!data) return null;
    const classes = useStyles(data);

    return (
        <div className={classes.container}>
            <img className={classes.image} src={data.background_image?.url} alt={data.background_image?.description || 'SideBySide Banner'} />
            <SBSCopy
                data={data}
            />
        </div>
    );
};

SideBannerPanel.propTypes = {
    data: shape({
        background_image: shape({
            url: string,
        }),
    }),
};
SideBannerPanel.defaultProps = {
    data: {},
};

export default (SideBannerPanel);
