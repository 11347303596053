/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, string, object } from 'prop-types';
import { useSelector } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import SideBannerPanel from './SideBannerPanel';
import AdvancedCopyModule from './Partial/AdvancedCopyModule';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        margin: '0 auto 32px',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '1400px',
        [theme.breakpoints.down(1367)]: {
            width: '100%',
        },
        [theme.breakpoints.down(599)]: {
            flexDirection: 'column',
            width: '100%',
            margin: '0 auto 16px',
        },
    },
    rootWithGrid: {
        position: 'relative',
        display: 'flex',
        margin: '0 auto 0 !important',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '1400px',
        [theme.breakpoints.down(1367)]: {
            width: '100%',
        },
        [theme.breakpoints.down(599)]: {
            flexDirection: 'column',
            width: '100%',
            margin: '0 auto 16px',
        },
    },
    sideBanner: {
        width: '100%',
        [theme.breakpoints.down(599)]: {
            width: '100%',
        },

    },
    sideBannerWrapper: {
        '& $sideBanner': {
            width: '50%',
            '&:first-child': {
                paddingRight: '16px',
            },
            '&:last-child': {
                paddingLeft: '16px',
            },
            [theme.breakpoints.down(769)]: {
                '&:first-child': {
                    paddingRight: '8px',
                },
                '&:last-child': {
                    paddingLeft: '8px',
                },
            },

            [theme.breakpoints.down(599)]: {
                width: '100%',
                '&:first-child': {
                    padding: '0px 0px 16px 0px',
                },
                '&:last-child': {
                    paddingLeft: '0px',
                },
            },

        },
    },
    advanceCopyWrapper: {
        [theme.breakpoints.down(725)]: {
            flexDirection: 'column',
            width: '100%',
        },
        '& $sideBanner': {
            [theme.breakpoints.down(725)]: {
                width: '100%',
                '&:first-child': {
                    padding: '0px 0px 16px 0px',
                },
                '&:last-child': {
                    paddingLeft: '0px',
                },
            },
        },
    },
});

const SideBySideBanner = ({
    data: blockData, classes, isPersonalized, content_type,
}) => {
    let entries;
    let _content_type_uid;

    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const rootCssStyle = ffHasHomepageGridStructure ? classes.rootWithGrid : classes.root;

    if (isPersonalized) {
        entries = blockData?.entries;
        _content_type_uid = content_type;
        const panelClass = entries?.length > 1 ?  classes.sideBannerWrapper : '';
        const isSideBannerTrue = _content_type_uid === 'side_by_side_image_banner';

        return  (
            <div className={`${rootCssStyle} ${panelClass} ${!isSideBannerTrue ? classes.advanceCopyWrapper : ''}`}>
                {entries?.map((item) => (
                    <div className={`${classes.sideBanner}`}>
                        {isSideBannerTrue
                            ? <SideBannerPanel data={item}  />
                            : <AdvancedCopyModule  data={item} />}
                    </div>
                ))}
            </div>
        );
    }

    const { data, loading } = useUIDQueryConditionally({ data: blockData });
    if (!data || !data.reference || !data.reference.length) return null;
    const uid = data.reference[0]?.uid;
    const unique_selector = data.reference[0]?.unique_selector;
    entries = data.reference[0]?.entries;
    _content_type_uid = data.reference[0]?._content_type_uid;

    if (loading) {
        return (
            <DesktopMobile
                desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
            />
        );
    }

    const dataUniqueId = uniqueId(unique_selector);
    // if 1 row and two elements, create narrower gap between both that usual.
    const panelClass = entries?.length > 1 ?  classes.sideBannerWrapper : '';
    const isSideBannerTrue = _content_type_uid === 'side_by_side_image_banner';
    return  (
        <div className={`${rootCssStyle} ${panelClass} ${!isSideBannerTrue ? classes.advanceCopyWrapper : ''}`}  data-uniqueid={dataUniqueId} cmsuid={uid}>
            {entries?.map((item) => (
                <div className={`${classes.sideBanner}`}>
                    {isSideBannerTrue
                        ? <SideBannerPanel data={item}  />
                        : <AdvancedCopyModule  data={item} />}
                </div>
            ))}

        </div>
    );
};

SideBySideBanner.propTypes = {
    data: object,
    classes: object.isRequired,
    isPersonalized: bool,
    content_type: string,
};

SideBySideBanner.defaultProps = {
    data: {},
    isPersonalized: false,
    content_type: '',
};

export default  withStyles(styles)(SideBySideBanner);
