/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, number, shape, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Copy from '../../Copy';
import convertAlphaDecToHex from '../../../../helpers/contentstack/convertAlphaDecToHex';
import getBackgroundColor from '../../../../helpers/getBackgroundColor';
import LinkOrATag from '../../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { generateDomId } from '../../../../helpers/common/helper';

// use for general message container style
const messageContainerStyle = (styleData) => {
    const messageContainer = {};
    if (styleData && Array.isArray(styleData)) {
        styleData.forEach((styleObj) => {
            const styleKey = Object.keys(styleObj)[0];
            switch (styleKey) {
                case 'margin':
                    messageContainer.marginTop = `${styleObj[styleKey]?.top}px`;
                    messageContainer.marginRight = `${styleObj[styleKey]?.right}px`;
                    messageContainer.marginBottom = `${styleObj[styleKey]?.bottom}px`;
                    messageContainer.marginLeft = `${styleObj[styleKey]?.left}px`;
                    break;
                case 'padding':
                    messageContainer.paddingTop = `${styleObj[styleKey]?.top}px`;
                    messageContainer.paddingRight = `${styleObj[styleKey]?.right}px`;
                    messageContainer.paddingBottom = `${styleObj[styleKey]?.bottom}px`;
                    messageContainer.paddingLeft = `${styleObj[styleKey]?.left}px`;
                    break;
                case 'bg_color':
                    if (styleObj[styleKey]?.background_opacity) {
                        messageContainer.backgroundColor = convertAlphaDecToHex(styleObj[styleKey]?.color?.color, styleObj[styleKey]?.background_opacity);
                    } else {
                        messageContainer.backgroundColor = styleObj[styleKey]?.color.color;
                    }
                    break;
                case 'border':
                    messageContainer.border = `${`${styleObj[styleKey]?.width}px` } ${getBackgroundColor(styleObj[styleKey]?.color)} ${styleObj[styleKey]?.style}`;
                    messageContainer.borderRadius = `${styleObj[styleKey]?.unit?.toLowerCase() === 'pixel' ? `${styleObj[styleKey]?.border_radius}px` : `${styleObj[styleKey]?.border_radius}%` }`;
                    break;
                default:
                    return {};
            }
            return messageContainer;
        });
    }
    return messageContainer;
};

// set message container position
const messageContainerPosition = (message_location) => {
    if (message_location) {
        let alignContainer = 'flex-start';
        switch (message_location) {
            case 'left':
                alignContainer = 'flex-start';
                break;
            case 'center':
                alignContainer = 'center';
                break;
            case 'right':
                alignContainer = 'flex-end';
                break;
            default:
                return alignContainer;
        }
        return alignContainer;
    }
    return null;
};

const useStyles = makeStyles((theme) => ({
    copyContainer: ({ messageAlignment }) => ({
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: messageContainerPosition(messageAlignment?.text_align) || 'center',
        textDecoration: 'none',
    }),
    copyMainContainer: ({ containerWidth  }) => ({
        [theme.breakpoints.up(1200)]: {
            width: `${containerWidth?.max_width_desktop}${containerWidth?.unit === 'Percentage' ? '%' : 'px'}` || '40%',
        },
        '@media screen and (min-width: 600px) and (max-width: 1200px)': {
            width: 'auto',
            display: 'table',
        },
        [theme.breakpoints.down(599)]: {
            width: `${containerWidth?.max_width_mobile}${containerWidth?.unit === 'Percentage' ? '%' : 'px'}` || '80%',
        },
    }),
    linkWrapper: ({ data }) => ({
        display: 'flex',
        maxWidth: '1400px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: `url(${data?.background_image?.url}?auto=webp)`,
        textDecoration: 'none',
        color: '#2f2f2f',
        height: '300px',
        [theme.breakpoints.down(1366)]: {
            backgroundImage: `url(${data?.background_image_tablet?.url || data?.background_image?.url}?auto=webp)`,
            backgroundPositionX: data.styling?.tablet_x_offset || null,
        },
        [theme.breakpoints.down(599)]: {
            backgroundImage: `url(${data?.background_image_mobile?.url || data?.background_image?.url }?auto=webp)`,
        },
    }),
    copyInnerContainer: ({ copyCollectionContainer }) => ({
        ...messageContainerStyle(copyCollectionContainer),
        [theme.breakpoints.down(599)]: {
            margin: '10px',
        },
    }),
}));

const AdvancedCopyModule = ({
    data,
}) => {
    if (!data) return null;
    const copyModuleData = data.reference?.[0];
    const copyCollection = copyModuleData?.message_collection;
    const copyCollectionContainer = copyModuleData?.general_message_styling || [];
    const containerWidth = copyCollectionContainer?.filter((widthStyle) => Object.keys(widthStyle)?.[0] === 'width')?.[0]?.width;
    const messageAlignment = copyCollectionContainer?.filter((messageAlign) => Object.keys(messageAlign)?.[0] === 'message_alignment')?.[0]?.message_alignment;

    const classes = useStyles({
        copyCollectionContainer,
        messageAlignment,
        containerWidth,
        data,
    });
    const getCtaTitle =  (copyCollection || []).find?.((item) => (Object.keys(item || {}).includes?.('cta')))?.cta?.cta || '';
    return (
        <LinkOrATag
            href={data?.linking?.link?.href}
            id={generateDomId(data?.linking?.link?.href)}
            trackingEventAction={data?.linking?.tracking_event_action}
            trackingEventCategory={data?.linking?.tracking_event_category}
            trackingEventLabel={data?.linking?.tracking_event_label}
            isTrackEventEnabled
            title={getCtaTitle}
            className={classes.linkWrapper}
        >
            <div className={classes.copyContainer}>
                <div className={`${classes.copyInnerContainer} ${classes.copyMainContainer}`}>
                    <Copy data={copyCollection} />
                </div>
            </div>
        </LinkOrATag>
    );
};

AdvancedCopyModule.propTypes = {
    data: shape({
        title: string,
        general_message_styling: arrayOf(shape({
            message_alignment: shape({
                text_align: string,
            }),
            color: shape({
                message_copy_color: shape({
                    color: string,
                }),
            }),
            bg_color: shape({
                background_opacity: number,
            }),
        })),
        message_collection: arrayOf(shape({
            copy: shape({
                copy: string,
                viewport: string,
            }),
        })),
    }),
};
AdvancedCopyModule.defaultProps = {
    data: {},
};

export default AdvancedCopyModule;
