/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, shape,
} from 'prop-types';
import Media from 'react-media';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import CTA from '../../Buttons/CTA';
import transformRenderer from '../../../../helpers/markdown/characterTransformer';
import gradient from '../../../../helpers/contentstack/gradient';
import useSSRMediaQuery from '../../../../helpers/hooks/useSSRMediaQuery';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const positionAdjust = (styling) => {
    const adjustPositionCSS = {
        justifyContent: 'center',
        alignItems: 'flex-start',
    };
    const arr =  styling?.message_positioning?.split(',') || [];
    arr?.forEach((value) => {
        if (value) {
            const [propName, propValue] = value?.split(':');
            adjustPositionCSS[propName] = propValue?.split("'")?.join('');
        }
    });
    return adjustPositionCSS;
};

const useStyles = makeStyles((theme) => ({
    messagePanel: (data) => ({
        position: 'absolute',
        top: '0px',
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        ...positionAdjust(data?.styling),
    }),
    title: (data) => ({
        '& h1,h2,h3,h4,h5,p': {
            fontSize: `${data?.styling?.title_font_size}rem` || '40px',
            lineHeight: '48px',
            margin: '0px',
            padding: '0px 0px 20px',
            color: data.styling?.title_color?.color || '#2f2f2f',
            fontFamily: parseFont(data?.styling?.title_font_family),
            [theme.breakpoints.down(1366)]: {
                fontSize: '1.8em',
                lineHeight: '1.4em',
                padding: '0px 0px 10px',
            },
            [theme.breakpoints.down(980)]: {
                lineHeight: '36px',
                padding: '0px 0px 10px',
                fontSize: '20px',
            },
            [theme.breakpoints.down(599)]: {
                lineHeight: '32px',
                fontSize: '24px',
                padding: '0px 0px 22px',
            },
        },
    }),
    panel: () => ({
        padding: '0px 36px',
        width: '326px',
        [theme.breakpoints.down(1366)]: {
            padding: '0px 16px',
            width: '72%',
        },
        [theme.breakpoints.down(767)]: {
            padding: '0px 12px',
            width: '72%',
        },
        [theme.breakpoints.down(599)]: {
            padding: '0px 13px',
            width: '70%',
        },
    }),
    messageWrapper: (data) => ({
        width: '72%',
        height: '100%',
        display: 'flex',
        textAlign: 'center',
        background: gradient(data?.styling?.background_gradient),
        flexDirection: 'column',
        ...positionAdjust(data?.styling),
    }),
    copy: (data) => ({
        '& h1,h2,h3,h4,h5,p': {
            fontSize: `${data?.styling?.description_font_size}rem` || '16px',
            color: data.styling?.description_color?.color || '#2f2f2f',
            fontFamily: parseFont(data?.styling?.description_font_family),
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.01em',
            margin: '0px',
            padding: '0px 0px 30px',
            [theme.breakpoints.down(1025)]: {
                padding: '0px 0px 20px',
            },
        },
    }),
    viewButton: (data) => ({
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            border: `1px solid ${data?.styling?.cta_background_color?.color ||  theme.palette.colorPrimary }`,
            lineHeight: '18px',
            letterSpacing: '0.04em',
            fontStyle: 'normal',
            fontWeight: 'bold',
        },
    }),
}));

const SBSCopy = ({ data }) => {
    const {
        copy = '',  title = '', linking = {}, styling = {},
    } = data;

    const classes = useStyles(data);
    const device = useSSRMediaQuery();

    // background color transparent when use color style none
    const hasTransparentBackground = (bgColor) => {
        const setBackgroundColor = bgColor?.colorTheme;
        if (setBackgroundColor) {
            return  setBackgroundColor === 'none' ? 'transparent' : bgColor?.color;
        }
        return bgColor?.color;
    };

    return (
        <div className={classes.messagePanel}>
            <div className={classes.messageWrapper}>
                <div className={classes.panel}>
                    {title
                        ? (
                            <div className={classes.title}>
                                <ReactMarkdown renderers={transformRenderer} source={title?.replace(/<p>|<\/p>/g, '')} escapeHtml={false} />
                            </div>
                        ) : ''}
                    { copy ? (
                        <Media
                            query="(min-width: 320px) and (max-width: 1023px)"
                            defaultMatches={device === 'mobile'}
                        >
                            {(matches) => (
                                !matches
                                    ? (
                                        <div className={classes.copy}>
                                            <ReactMarkdown renderers={transformRenderer} source={copy?.replace(/<p>|<\/p>/g, '')} escapeHtml={false} />
                                        </div>
                                    )
                                    : ''
                            )}
                        </Media>
                    ) : ''}
                    {linking?.link?.href && (
                        <div className={classes.viewButton}>
                            <CTA
                                copy={linking?.link?.title}
                                href={linking?.link?.href}
                                title={linking?.link?.title}
                                fontFamily={styling?.cta_font_family}
                                backgroundColor={hasTransparentBackground(styling?.cta_background_color)}
                                fontColor={styling?.cta_color?.color}
                                tracking_event_category={linking?.tracking_event_category}
                                tracking_event_action={linking?.tracking_event_action}
                                tracking_event_label={linking?.tracking_event_label}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

SBSCopy.propTypes = {
    data: shape({
        title: string,
        copy: string,
        linking: shape({
            tracking_event_category: string,
            tracking_event_label: string,
            tracking_event_action: string,
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }).isRequired,
        }),
        styling: shape({
            cta_font_family: string,
            cta_background_color: shape({
                color: string,
            }),
            cta_color: shape({
                color: string,
            }),
        }),
    }),
};
SBSCopy.defaultProps = {
    data: {},
};

export default SBSCopy;
